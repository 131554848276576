import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import axios from '@axios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'inicio',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    // Alertas
    {
      path: '/alertas',
      name: 'alertas',
      component: () => import('@/views/Alertas/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '⚠️ Alertas',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Alertas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/alertas/historial',
      name: 'alertas-historial',
      component: () => import('@/views/Alertas/History.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '⚠️ Historial',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Alertas',
          },
          {
            text: 'Historial',
            active: true,
          },
        ],
      },
    },
    {
      path: '/alertas/agregar',
      name: 'alertas-agregar',
      component: () => import('@/views/Alertas/addError.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '⚠️ Agregar Alerta',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Alertas',
          },
          {
            text: 'Agregar Alerta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/alerta/:idError',
      name: 'ver-alerta',
      component: () => import('@/views/Alertas/SingleError.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '⚠️ Alerta',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Lista',
          },
          {
            text: 'Alerta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/editar-alerta/:idError',
      name: 'editar-alerta',
      component: () => import('@/views/Alertas/addError.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '⚠️ Editar Alerta',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Alertas',
          },
          {
            text: 'Editar Alerta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/publico/alerta/:idError',
      name: 'publico-ver-alerta',
      component: () => import('@/views/Alertas/SingleError.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
        pageTitle: '⚠️ Alerta',
      },
    },
    {
      path: '/publico/listado-alertas',
      name: 'publico-listado-alertas',
      component: () => import('@/views/Alertas/Index.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
        pageTitle: '⚠️ Listado Alertas',
      },
    },
    // tareas
    {
      path: '/tareas',
      name: 'tareas',
      component: () => import('@/views/Tareas/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '📝 Tareas',
        role: ['master', 'automatizacion', 'colab'],
        breadcrumb: [
          {
            text: 'Tareas',
            active: true,
          },
        ],
      },
    },
    // metas
    {
      path: '/metas-azucar',
      name: 'metas-azucar',
      component: () => import('@/views/Goals/Sugar.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '🏭 Metas Azucar',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Metas Azucar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/metas-molienda',
      name: 'metas-molienda',
      component: () => import('@/views/MetasMolienda/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '🏭 Metas Molienda',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Metas Molienda',
            active: true,
          },
        ],
      },
    },
    // elencos
    {
      path: '/elencos',
      name: 'elencos',
      component: () => import('@/views/Elencos/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: '📚 Elencos',
        role: ['master', 'automatizacion', 'colab'],
        breadcrumb: [
          {
            text: 'Elencos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/buscador-elencos',
      name: 'buscador-elencos',
      component: () => import('@/views/Elencos/Buscador.vue'),
      meta: {
        layout: 'full',
        requiresAuth: false,
        pageTitle: '📚 Buscador Elencos',
        role: ['master', 'automatizacion', 'colab'],
        breadcrumb: [
          {
            text: 'Elencos',
            active: false,
          },
          {
            text: 'Busqueda',
            active: true,
          },
        ],
      },
    },
    {
      path: '/equipos',
      name: 'equipos',
      component: () => import('@/views/Elencos/Equipos.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Equipos',
        role: ['master'],
        breadcrumb: [
          {
            text: 'Equipos',
            active: true,
          },
        ],
      },
    },
    // Configuraciones
    {
      path: '/configuraciones',
      name: 'configuraciones',
      component: () => import('@/views/Settings/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Configuraciones',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Configuraciones',
            active: true,
          },
        ],
      },
    },
    // Calibraciones
    {
      path: '/calibraciones',
      name: 'calibraciones',
      component: () => import('@/views/Calibraciones/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Calibraciones Fabrica',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Calibraciones',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calibraciones-bio',
      name: 'calibraciones-bio',
      component: () => import('@/views/Calibraciones/Index.vue'),
      meta: {
        isBio: true,
        requiresAuth: true,
        pageTitle: 'Calibraciones Bio-Etanol',
        role: ['master', 'bioetanol'],
        breadcrumb: [
          {
            text: 'Calibraciones Bio',
            active: true,
          },
        ],
      },
    },
    // Calibraciones
    {
      path: '/detector-metales',
      name: 'detector-metales',
      component: () => import('@/views/Detectores/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Detectores',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Metales',
            active: true,
          },
        ],
      },
    },
    // Bodegas
    {
      path: '/bodega-herramientas',
      name: 'bodega-herramientas',
      component: () => import('@/views/Bodegas/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Bodega Herramientas',
        role: ['master', 'automatizacion', 'bodegas'],
        breadcrumb: [
          {
            text: 'Bodegas',
            active: false,
          },
          {
            text: 'Herramientas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bodega-repuestos',
      name: 'bodega-repuestos',
      component: () => import('@/views/Bodegas/Repuestos.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Bodega Repuestos',
        // role: ['master', 'automatizacion', 'bodegas'],
        role: ['master'],
        breadcrumb: [
          {
            text: 'Bodegas',
            active: false,
          },
          {
            text: 'Repuestos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calibraciones/equipos',
      name: 'calibraciones-equipos',
      component: () => import('@/views/Calibraciones/Equipos.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Equipos',
        role: ['master', 'automatizacion'],
        breadcrumb: [
          {
            text: 'Calibraciones',
            active: false,
          },
          {
            text: 'Equipos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calibraciones-bio/equipos',
      name: 'calibraciones-bio-equipos',
      component: () => import('@/views/Calibraciones/Equipos.vue'),
      meta: {
        isBio: true,
        requiresAuth: true,
        pageTitle: 'Equipos BioEtanol',
        role: ['master', 'bioetanol'],
        breadcrumb: [
          {
            text: 'Calibraciones Bio',
            active: false,
          },
          {
            text: 'Equipos',
            active: true,
          },
        ],
      },
    },
    // Laboratorio
    {
      path: '/laboratorio',
      name: 'laboratorio',
      component: () => import('@/views/Lab/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Laboratorio',
        role: ['lab_admin', 'master', 'laboratorio'],
        breadcrumb: [
          {
            text: 'Laboratorio',
            active: true,
          },
          {
            text: 'Muestras',
            active: true,
          },
        ],
      },
    },
    {
      path: '/laboratorio-configuracion',
      name: 'laboratorio-configuracion',
      component: () => import('@/views/Lab/Settings.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Configuraciones',
        role: ['lab_admin', 'master'],
        breadcrumb: [
          {
            text: 'Laboratorio',
            active: true,
          },
          {
            text: 'Configuraciones de Laboratorio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/laboratorio-registros',
      name: 'laboratorio-registros',
      component: () => import('@/views/Lab/Reg/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Registros de Laboratorio',
        role: ['lab_admin', 'master', 'laboratorio'],
        breadcrumb: [
          {
            text: 'Laboratorio',
            active: true,
          },
          {
            text: 'Registros',
            active: true,
          },
        ],
      },
    },
    // Usuarios
    {
      path: '/usuarios',
      name: 'usuarios',
      component: () => import('@/views/Users/ListUsers.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Usuarios',
        role: 'master',
        breadcrumb: [
          {
            text: 'Usuarios',
            active: true,
          },
        ],
      },
    },
    // login
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        blockLogueed: true,
        layout: 'full',
      },
    },
    // otras paginas 404
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

async function getRoleCurrentUser(uid) {
  return new Promise((resolve, reject) => {
    firebase.database().ref(`users/${uid}/rol`).once('value', snap => {
      resolve(snap.val())
    })
  })
}

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(async () => {
    const firebaseCurrentUser = firebase.auth().currentUser
    if (to.meta.requiresAuth) {
      if (firebaseCurrentUser) {
        to.meta.currentUserId = firebaseCurrentUser.uid
        axios.defaults.headers.common.Authorization = await firebaseCurrentUser.getIdToken(true)
        // Obtener el rol del usuario
        getRoleCurrentUser(firebaseCurrentUser.uid).then(value => {
          to.meta.currentUserRol = value
          from.meta.currentUserRol = value
          if (to.meta.requiresAuth) {
            if (firebaseCurrentUser.uid && to.meta.role) {
              if (!to.meta.role.includes(value)) router.back()
            }
          }
        })
      } else {
        to.meta.currentUserId = ''
        router.push({ path: '/login', query: { to: to.path } }).catch(() => {})
      }
    }
    if (to.meta.blockLogueed && firebaseCurrentUser) {
      router.push({ path: '/' }).catch(() => {})
    }
    return next()
  })
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
