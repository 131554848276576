import Vue from 'vue'
import store from "@/store/index"
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: store.state.api_url,
  // timeout: 1000,
  headers: {
    "Content-Type": 'application/json'    
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns
