
import firebase from '@firebase/app'
const firebaseConfig = {
  apiKey: "AIzaSyCbmf_pYoADhSlLFac7Fn8U7ag9mVgTaxo",
  authDomain: "automatizacion-ffaf9.firebaseapp.com",
  databaseURL: "https://automatizacion-ffaf9-default-rtdb.firebaseio.com",
  projectId: "automatizacion-ffaf9",
  storageBucket: "automatizacion-ffaf9.appspot.com",
  messagingSenderId: "788576775624",
  appId: "1:788576775624:web:6f57196e31afc2cd0524fd"
};
firebase.initializeApp(firebaseConfig);