import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api_url: 'https://apiv2.iiotautomat.com', // API URL USED IN ALL APP REQUESTS
    // api_url: 'http://localhost:9191', // API URL USED IN ALL APP REQUESTS
    requestsLog: [],
  },
  mutations: {
    addRequest(state, item) {
      state.requestsLog.push(item)
    },
  },
  actions: {
    addRequest({ commit }, item) {
      commit('addRequest', item)
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
